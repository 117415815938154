import logo from './logo.svg';
import React from "react";


export default function Header(){
    return(
      <div className='navbar'>
        <a href="/" 
            className="navbar-logo">
              JFM
        </a>
    
        

      </div>
    
    )
  }